import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'
import Image360Player from '@theme/components/utils/Image360Player'
import ImageZoomOnHover from '@theme/components/atom/ImageZoomOnHover'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal'
import VideoPlayer from '@theme/components/utils/VideoPlayer'

export default {
  mixins: [DetectMobileMixin, LoadingMixin],
  props: {
    items: Array,
    title: String,
  },
  data() {
    return {
      fadeImageIn: false,
      selectedItemIndex: 0,
      shakeItem: false,
      thumbnails: this.$themeSettings.components.LightboxGallery.thumbnails,
      thumbnailsWrapperComponent: null,
    }
  },
  components: {
    AppImage,
    AppTitle,
    Image360Player,
    ImageZoomOnHover,
    Modal,
    VideoPlayer,
  },
  computed: {
    isFirstItem() {
      return this.selectedItemIndex === 0
    },
    isLastItem() {
      return this.selectedItemIndex === this.items.length - 1
    },
    selectedItem() {
      return this.items[this.selectedItemIndex]
    },
  },
  mounted() {
    document.addEventListener('keyup', this.keyUpHandler)
    if (this.thumbnails.position === 'right' || this.thumbnails.position === 'left') {
      import('@theme/components/utils/SimpleBar').then(component => {
        this.thumbnailsWrapperComponent = component.default
      })
    } else {
      this.thumbnailsWrapperComponent = 'div'
    }
  },
  methods: {
    changeImage(event) {
      if (this.selectedItem.type !== 'image') {
        return
      }
      const element = this.$refs.selectedItemArea
      const clickPositonX = event.clientX - element.getBoundingClientRect().left
      const width = element.clientWidth
      if (clickPositonX < width / 2) {
        this.previousItem()
      } else {
        this.nextItem()
      }
    },
    open(index = 0) {
      this.selectItem(index)
      this.$refs.modal.show()
    },
    nextItem() {
      if (this.selectedItemIndex < this.items.length - 1) {
        this.selectItem(this.selectedItemIndex + 1)
      } else {
        this.shakeEdgeItem()
      }
    },
    previousItem() {
      if (this.selectedItemIndex > 0 && this.selectedItemIndex < this.items.length) {
        this.selectItem(this.selectedItemIndex - 1)
      } else {
        this.shakeEdgeItem()
      }
    },
    selectItem(index) {
      this.selectedItemIndex = index
      this.fadeImageIn = true
      setTimeout(() => {
        this.fadeImageIn = false
      }, 1000)
    },
    swipeHandler(direction) {
      if (this.selectedItem.type === '360image') {
        return
      }
      if (direction === 'left') {
        this.nextItem()
      }
      if (direction === 'right') {
        this.previousItem()
      }
    },
    keyUpHandler(event) {
      if (event.keyCode === 37 || event.keyCode === 38) {
        this.previousItem()
      } else if (event.keyCode === 39 || event.keyCode === 40) {
        this.nextItem()
      }
    },
    shakeEdgeItem() {
      this.shakeItem = true
      setTimeout(() => {
        this.shakeItem = false
      }, 1000)
    },
  },
}
