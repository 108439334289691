import ImageUrlMixin from '~/mixins/ImageUrlMixin'

export default {
  mixins: [ImageUrlMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
    },
    heightZoom: {
      type: Number,
      default() {
        return this.$themeSettings.components.ImageZoomOnHover.zoomImageHeight
      },
    },
    imgZoomUpscale: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: Number,
      default: 1,
    },
    src: {
      type: String,
      required: true,
    },
    srcZoom: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
    },
    widthZoom: {
      type: Number,
      default() {
        return this.$themeSettings.components.ImageZoomOnHover.zoomImageWidth
      },
    },
  },
  data() {
    return {
      resizeCheckInterval: null,
      scaleFactor: 1,
      isSmall: false,
      loaded: false,
      zoomOnHoverWidth: null,
    }
  },
  computed: {
    isGif() {
      return this.src?.endsWith('.gif')
    },
    imgNormal() {
      return this.imageUrl({
        src: this.src,
        width: this.width,
        height: this.height,
        upscale: this.imgZoomUpscale,
        imageStyle: this.isGif ? 'original' : 'custom',
      })
    },
    imgZoom() {
      return this.imageUrl({
        src: this.src,
        width: this.widthZoom,
        height: this.heightZoom,
        crop: this.widthZoom === null || this.heightZoom === null,
        upscale: this.imgZoomUpscale,
      })
    },
    sources() {
      return {
        normal: this.imgNormal,
        zoom: this.srcZoom ? [this.$env.STORE_URL, 'image', 'original', this.srcZoom].join('/') : this.imgZoom,
      }
    },
    sourcesWebp() {
      return {
        normal: this.sources.normal.replace(/(.*)\.(jpg|png)/, '$1.webp?original=$2'),
        zoom: this.sources.zoom.replace(/(.*)\.(jpg|png)/, '$1.webp?original=$2'),
      }
    },
  },
  methods: {
    adjustZoomOnHoverWidth() {
      this.$nextTick(() => {
        this.zoomOnHoverWidth = this.$refs.normal.width
      })
    },
    reloadIsSmall() {
      this.isSmall = this.$refs.wrapper.offsetHeight > this.$refs.normal.offsetHeight
    },
    zoom(event) {
      if (this.disabled) return
      this.$refs.zoom.style.opacity = 1
      this.$refs.normal.style.opacity = 0
      this.move(event)
    },
    unzoom() {
      if (this.disabled) return
      this.$refs.zoom.style.opacity = 0
      this.$refs.normal.style.opacity = 1
    },
    move(event) {
      if (this.disabled) return
      const offset = this.pageOffset(this.$el)
      const zoom = this.$refs.zoom
      const normal = this.$refs.normal
      const relativeX = event.clientX - offset.x + window.pageXOffset
      const relativeY = event.clientY - offset.y + window.pageYOffset
      const normalFactorX = relativeX / normal.offsetWidth
      const normalFactorY = relativeY / normal.offsetHeight
      const x = normalFactorX * (zoom.offsetWidth * this.scaleFactor - normal.offsetWidth)
      const y = normalFactorY * (zoom.offsetHeight * this.scaleFactor - normal.offsetHeight)
      zoom.style.left = -x + 'px'
      zoom.style.top = -y + 'px'
    },
    initEventLoaded() {
      // emit the "loaded" event if all images have been loaded
      const promises = [this.$refs.zoom, this.$refs.normal].map(image => {
        return new Promise(function(resolve, reject) {
          image.addEventListener('load', resolve)
          image.addEventListener('error', reject)
        })
      })
      Promise.all(promises).then(() => {
        this.$emit('loaded')
      })
    },
    initEventResized() {
      const normal = this.$refs.normal
      let previousWidth = normal.offsetWidth
      let previousHeight = normal.offsetHeight
      this.resizeCheckInterval = setInterval(() => {
        if (previousWidth !== normal.offsetWidth || previousHeight !== normal.offsetHeight) {
          previousWidth = normal.offsetWidth
          previousHeight = normal.offsetHeight
          this.$emit('resized', {
            width: normal.width,
            height: normal.height,
            fullWidth: normal.naturalWidth,
            fullHeight: normal.naturalHeight,
          })
        }
      }, 1000)
    },
    pageOffset(el) {
      // -> {x: number, y: number}
      // get the left and top offset of a dom block element
      const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return {
        y: rect.top + scrollTop,
        x: rect.left + scrollLeft,
      }
    },
  },
  mounted() {
    if (this.scale) {
      this.scaleFactor = parseInt(this.scale)
      this.$refs.zoom.style.transform = 'scale(' + this.scaleFactor + ')'
    }
    this.initEventResized()
    this.initEventLoaded()
  },
  beforeDestroy() {
    this.resizeCheckInterval && clearInterval(this.resizeCheckInterval)
  },
  beforeMount() {
    this.$on('loaded', () => {
      this.$nextTick(() => {
        this.reloadIsSmall()
        this.adjustZoomOnHoverWidth()
      })
    })
  },
}
