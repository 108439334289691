import { imagePreloader } from 'vue-image-preloader'
import AppImage from '@theme/components/atom/AppImage'
import ImageUrlMixin from '~/mixins/ImageUrlMixin' // https://github.com/andrepolischuk/circlr
import Loader from '@theme/components/utils/Loader'
import _ from 'lodash' // https://github.com/andrepolischuk/circlr
import circlr from 'circlr'

export default {
  mixins: [ImageUrlMixin],
  props: {
    images: Array,
    placement: String,
  },
  data() {
    return {
      circlr: null,
      circlrStopped: false,
      imgUrls: [],
      interval: null,
      height: 800,
      loadedAll: false,
    }
  },
  components: {
    AppImage,
    imagePreloader,
    Loader,
  },
  mounted() {
    this.imgUrls = this.imagesOrdered.map(image => this.imageUrl({ src: image.fileKey, height: this.height }))
  },
  computed: {
    imagesOrdered() {
      return this.images.sort((a, b) => b.fileKey.localeCompare(a.fileKey))
    },
  },
  methods: {
    loadedAllPics() {
      this.loadedAll = true
      this.circlr = circlr(this.$refs.wrapper360image)
        .scroll(true)
        .play()
    },
    playForward() {
      if (!this.interval) {
        this.interval = setInterval(() => this.showNext(), 60)
      }
    },
    playBackward() {
      if (!this.interval) {
        this.interval = setInterval(() => this.showPrevious(), 60)
      }
    },
    playContinous() {
      if (this.circlrStopped) {
        this.circlr.play()
        this.circlrStopped = false
        return
      }
      this.circlr.stop()
      this.circlrStopped = true
    },
    showNext() {
      this.circlrStopped = true
      this.circlr.stop().next()
    },
    showPrevious() {
      this.circlrStopped = true
      this.circlr.stop().prev()
    },
    stop() {
      clearInterval(this.interval)
      this.interval = null
    },
  },
}
