var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('client-only',[_c('Modal',{ref:"modal",attrs:{"in-middle-of-screen":false,"fullscreen":true,"aria-labelled-by":"LightboxGallery-title","modal-class":"Modal--lightbox"},scopedSlots:_vm._u([{key:"content",fn:function({hide}){return _c('div',{key:_vm.selectedItemIndex,staticClass:"LightboxGallery__wrapper"},[_c('div',{staticClass:"LightboxGallery__title",attrs:{"id":"LightboxGallery-title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"row",class:{
            'flex-column-reverse': _vm.thumbnails.enable && _vm.thumbnails.position === 'top',
            'flex-column': _vm.thumbnails.enable && _vm.thumbnails.position === 'bottom',
            'flex-row-reverse': _vm.thumbnails.enable && _vm.thumbnails.position === 'left',
          }},[_c('div',{staticClass:"col-24",class:{'col-md-20': _vm.thumbnails.enable && (_vm.thumbnails.position === 'right' || _vm.thumbnails.position === 'left')}},[_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe",value:(_vm.swipeHandler),expression:"swipeHandler",arg:"swipe"}],ref:"selectedItemArea",staticClass:"LightboxGallery__selectedItem",class:{
                'LightboxGallery__selectedItem--loading': _vm.loading,
                'LightboxGallery__selectedItem--videoWrapper': _vm.selectedItem && _vm.selectedItem.type === 'video'
              },on:{"keyup":_vm.keyUpHandler,"click":function($event){return _vm.changeImage($event)}}},[(!_vm.isMobileDetected && _vm.selectedItem && _vm.selectedItem.type === 'image')?_c('ImageZoomOnHover',{key:_vm.selectedItem.src,class:{
                  'LightboxGallery__selectedItemImageFadingIn': _vm.fadeImageIn,
                  'shake': _vm.shakeItem
                },attrs:{"src":_vm.selectedItem.src,"width":946}}):_vm._e(),(_vm.isMobileDetected && _vm.selectedItem && _vm.selectedItem.type === 'image')?_c('AppImage',{key:_vm.selectedItem.src,class:{
                  'LightboxGallery__selectedItemImageFadingIn': _vm.fadeImageIn,
                  'shake': _vm.shakeItem
                },attrs:{"src":_vm.selectedItem.src,"alt":_vm.selectedItem.title,"lazy":false,"image-class":"LightboxGallery__selectedItemImage","image-style":"original"}}):_vm._e(),(_vm.selectedItem && _vm.selectedItem.type === 'video')?_c('VideoPlayer',{staticClass:"LightboxGallery__selectedItemVideo",attrs:{"video":_vm.selectedItem.media_object}}):_vm._e(),(_vm.selectedItem && _vm.selectedItem.type === '360image')?_c('Image360Player',{staticClass:"LightboxGallery__selectedItem360Image",attrs:{"images":_vm.selectedItem.media_object,"placement":"lightbox"}}):_vm._e(),_c('div',{staticClass:"LightboxGallery__selectedItemControl LightboxGallery__selectedItemControl--previous",class:{'LightboxGallery__selectedItemControl--inactive': _vm.isFirstItem},on:{"click":function($event){$event.stopPropagation();return _vm.previousItem.apply(null, arguments)}}},[_c('svgicon',{staticClass:"svg-down",attrs:{"icon":require('@icon/right-chevron.svg?raw'),"preserveAspectRatio":"none"}})],1),(_vm.$themeSettings.components.LightboxGallery.showCounterIndicator)?_c('div',{staticClass:"LightboxGallery__selectedItemCounterIndicator",class:[
                  _vm.selectedItem ? 'd-block' : 'd-none',
                  _vm.shakeItem ? 'shake' : ''
                ]},[_c('span',[_vm._v(_vm._s(_vm.selectedItemIndex + 1))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(_vm.items.length))])]):_vm._e(),_c('div',{staticClass:"LightboxGallery__selectedItemControl LightboxGallery__selectedItemControl--next",class:{'LightboxGallery__selectedItemControl--inactive': _vm.isLastItem},on:{"click":function($event){$event.stopPropagation();return _vm.nextItem.apply(null, arguments)}}},[_c('svgicon',{attrs:{"icon":require('@icon/right-chevron.svg?raw'),"preserveAspectRatio":"none"}})],1)],1)]),(_vm.thumbnails.enable)?_c('div',{staticClass:"col-24",class:{'col-md-4': _vm.thumbnails.position === 'right' || _vm.thumbnails.position === 'left'}},[_c('div',{staticClass:"LightboxGallery__thumbnails",class:_vm.thumbnails.position === 'right' || _vm.thumbnails.position === 'left'
                  ? 'LightboxGallery__thumbnails--vertical'
                  : 'LightboxGallery__thumbnails--horizontal'},[(_vm.thumbnailsWrapperComponent)?_c(_vm.thumbnailsWrapperComponent,{tag:"component"},[_c('div',{staticClass:"LightboxGallery__thumbnailsInnerWrapper"},[_vm._l((_vm.items),function(item,index){return [_c('div',{key:item.src + index,staticClass:"LightboxGallery__thumbnail",class:{
                        'LightboxGallery__thumbnail--selected': index === _vm.selectedItemIndex,
                        'LightboxGallery__thumbnail--icon' : item.type === 'video' || item.type === '360image'
                      },on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(index)}}},[(item.type === 'image')?_c('AppImage',{attrs:{"src":item.src,"width":_vm.thumbnails.width,"height":_vm.thumbnails.height,"alt":item.title}}):_vm._e(),(item.type === 'video')?_c('svgicon',{staticClass:"svg-icon-lg",attrs:{"icon":require('@icon/video.svg?raw')}}):_vm._e(),(item.type === '360image')?_c('svgicon',{staticClass:"svg-icon-lg",attrs:{"icon":require('@icon/360-degrees.svg?raw')}}):_vm._e()],1)]})],2)]):_vm._e()],1)]):_vm._e()])])}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }